<template>
  <div class="container">
    <van-nav-bar title="">
      <template #left>
        <van-icon :size="24" @click="$router.go(-1)" color="#141212" name="arrow-left" slot="left" />
      </template>
    </van-nav-bar>
    <iframe :src="src" frameborder="0"></iframe>
  </div>
</template>

<script>
  import { NavBar, Icon} from 'vant'
  export default {
    name: "outWindow",
    components:{
      'van-icon':Icon,
      'van-nav-bar':NavBar,
    },
    data() {
      return {
        // src:''
        src:decodeURI(this.$route.query.interlinkage)
      }
    },
    created(){

    },
    methods: {}
  }
</script>

<style scoped lang="scss">
  iframe{
    width: 100%;
    height: calc(100vh - 45px);
  }
</style>
